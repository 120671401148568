//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CountryCard from './components/countryCard.vue';
import ViewportMixin from '@/mixins/viewport.mixin';
import SwiperMixin from '~/mixins/swiper.mixin.js';
import ArrowIcon from '~/assets/svg/ic-arrow-right.svg?inline';

export default {
  components: { CountryCard, ArrowIcon },
  mixins: [ViewportMixin, SwiperMixin],

  watch: {
    async isMobile(value) {
      await this.$nextTick();

      if (!value) {
        this.initSwiper();
      } else {
        if (this.swiper !== null) {
          this.swiper.destroy();
          this.swiper = null;
        }
      }
    },
  },

  mounted() {
    if (!this.isMobile) {
      this.initSwiper();
    }
  },

  methods: {
    modal(country) {
      this.debug({ name: 'modal' }, 'country = %O', country);

      const prefix = 'rt0822';
      const section = 'countries';
      this.$store.dispatch('modalNotify/OPEN', {
        title: this.$t(`${prefix}.${section}.${country}.title`),
        description: this.$t(`${prefix}.${section}.${country}.text`),
        displayDescriptionAsHtml: true,
      });
    },
  },
};
